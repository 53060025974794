import ninjaConfig from '../config/ninja';
import { VALUE_DIVIDER } from '../const';
import { cookieStorage, getCookieExpirationDate, getCookieName } from '../cookies';

/**
 * @typedef {Object} LQDefinition
 * @prop {number} [nextRefresh] [0]
 * @prop {string} [currentSession] [1]
 * @prop {string} [trackedExperiments] [2]
 * @prop {number} [nextSurveyShow] [3]
 * @prop {string} [showedSurveys] [4]
 * @prop {boolean} [isDefinitionsForUserId] [5]
 */

// Handles laquesis definitiona reading and writing (lqstatus cookie)
export const cookieNameLq = getCookieName('lqstatus');

export function readLQDefinition() {
  return cookieStorage.get(cookieNameLq);
}

/**
 * @param {LQDefinition} def
 */
export function writeLQDefinition(def) {
  // Order is important!
  const arrayValue = [
    def.nextRefresh,
    def.currentSession || '',
    def.trackedExperiments,
    def.nextSurveyShow || '',
    def.showedSurveys,
    def.isDefinitionsForUserId ? '1' : '0',
  ];

  cookieStorage.set(cookieNameLq, arrayValue.join(VALUE_DIVIDER), {
    expires: getCookieExpirationDate(1, 'year'),
    path: '/',
    domain: ninjaConfig.cookieDomain,
  });
}

/**
 * Validate and get string consistency
 * @returns {LQDefinition | undefined}
 */
export function getLQDefinition() {
  // module.lqDefinition[0] == Timestamp => Next Refresh
  // module.lqDefinition[1] == 7832af83x1123 => Current session
  // module.lqDefinition[2] == exp-1#exp-2 => Tracked experiments in this session
  // module.lqDefinition[3] == Timestamp => Next survey show
  // module.lqDefinition[4] == survey_id1#survey_id2 => Showed surveys
  // module.lqDefinition[5] == 1|0 => 0 = sl, 1 = ui
  const defString = readLQDefinition();

  if (!defString) {
    return {};
  }

  const parts = defString.split(VALUE_DIVIDER);

  /**
   * @type {LQDefinition}
   */
  const def = {
    nextRefresh: parts[0],
    currentSession: parts[1] || '',
    trackedExperiments: parts[2],
    nextSurveyShow: parts[3],
    showedSurveys: parts[4],
    isDefinitionsForUserId: parts[5] === '1',
  };

  // validate each part
  if (/\d{10}/.test(def.nextRefresh)) {
    def.nextRefresh = parseInt(def.nextRefresh, 10);
  } else {
    def.nextRefresh = 0;
  }

  // JIRA_REGEX = /^[A-Z]{1}[A-Z\d]{0,9}-\d+$/ - we use lowecase here
  if (!/^([a-z]{1}[a-z\d]{0,9}-\d+#?)+$/.test(def.trackedExperiments)) {
    def.trackedExperiments = '';
  }

  if (/\d{10}/.test(def.nextSurveyShow)) {
    def.nextSurveyShow = parseInt(def.nextSurveyShow, 10);
  } else {
    def.nextSurveyShow = 0;
  }

  if (!/^(\d+#?)+[^#]$/.test(def.showedSurveys)) {
    def.showedSurveys = '';
  }

  return def;
}
