import hydraConfig from '@ninja/ninja/config/hydra';
import ninjaConfig from '@ninja/ninja/config/ninja';
import { ChannelName, HYDRA_HOST } from '@ninja/ninja/const';
import { currentSession, currentSessionLong } from '@ninja/ninja/core';
import { getProtocol } from '@ninja/ninja/utils';
import { currentPlatform } from '../hydra';
import regionConfig from '@ninja/ninja/config/region';
import { trackWithBeacon } from '@ninja/ninja/core/utils';

export const SURVEY_TRIGGER_MATCH = 'survey_trigger_match';
export const SURVEY_LOAD = 'survey_load';
export const SURVEY_ALREADY_ACTIVE = 'survey_already_active';
export const SURVEY_LOAD_ERROR = 'survey_load_error';
export const SURVEY_RENDER_START = 'survey_render_start';
export const SURVEY_RENDER = 'survey_render';
export const SURVEY_MARK_SHOWN = 'survey_mark_shown';

/**
 * Logs survey activity (non-related to main survey tracking) - trigger, fetch, open, etc.
 * Not checked by Grumpy ninja - everything is tracked "as-is"
 * @param {string} eventName
 * @param {number} surveyId
 * @param {Record<string, unknown>} debugProps
 */
export function debugSurvey(eventName, surveyId, debugProps) {
  const url = getProtocol() + HYDRA_HOST + hydraConfig.survey_path;
  let params = {
    survey_id: surveyId,
    debug_log: JSON.stringify(debugProps),
  };

  // Common Properties
  params.eN = eventName;
  params.sl = currentSessionLong;
  params.s = currentSession;
  params.cC = hydraConfig.params.cC;
  params.bR = hydraConfig.params.bR;

  if (ninjaConfig.platform === 'm') {
    params.cH = ChannelName.Mobile;
  } else if (ninjaConfig.platform === 'd') {
    params.cH = ChannelName.Desktop;
  } else if (undefined !== currentPlatform) {
    params.cH = currentPlatform;
  } else {
    params.cH = 'w';
  }

  // Matrix Version
  if (undefined !== regionConfig.version) {
    params.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    params.env = 'dev';
  }

  // Timestamp
  params.t = new Date().getTime();

  // console.log('Survey debug:', params);
  trackWithBeacon(url, params);
}
