import hydraConfig from '../../config/hydra';
import ninjaConfig from '../../config/ninja';
import regionConfig from '../../config/region';
import {
  ChannelName,
  EVENTS,
  HYDRA_HOST,
  JSQR_URL,
  LAQUESIS_QA_URL,
  LAQUESIS_SURVEY_CSS_FILENAME,
  LQ_SURVEY_TRIGGERS_STORAGE_KEY,
  LQ_SURVEY_TYPES_STORAGE_KEY,
  NINJA_CDN,
  SPLITTER_CDN,
  TEST_DIVIDER,
  VALUE_DIVIDER,
  VARIANT_DIVIDER,
} from '../../const';
import { cookieStorage, getCookieExpirationDate, getCookieName } from '../../cookies';
import { collectCalls, cookieFromLq, currentSession, currentSessionLong, trackError } from '../../core';
import { trackWithBeacon } from '../../core/utils';
import { getLQDefinition, writeLQDefinition } from '../../laquesis/definitions';
import { makeMapping } from '../../mapping';
import { ajaxCallPromise, deucex, eucex, getNow, getProtocol, loadCSS, loadScript, objectToQueryString } from '../../utils';
import { triggerCustomEvent } from '../../utils/event';
import { hasUserInteractedWithPage } from '../../web-vitals';
import { convertString, currentPlatform, firstTrackPage } from '../hydra';
import { canShowSurvey, getSurveyData, isSurveyAvailable, markSurveyAsShown } from './surveyConditions';
import {
  SURVEY_ALREADY_ACTIVE,
  SURVEY_TRIGGER_MATCH,
  SURVEY_LOAD_ERROR,
  SURVEY_MARK_SHOWN,
  SURVEY_RENDER,
  SURVEY_RENDER_START,
  debugSurvey,
} from './surveyDebug';

let currentLqPush;

let retries = 0;
let trackPageRetries = 0;
let initPushed = false;
// let cookieRegistry = null;
let listOfActiveTests = [];
let listOfActiveFlags = [];

/**
 * @type {import('../../laquesis/definitions').LQDefinition}
 */
let lqDefinition = {};
let userId = null;
let localTestsCookieValue = null;
let localFlagsCookieValue = null;
let localSurveysCookieValue = null;
let counter = 0;
let activeSurvey = false;
let isLaquesisQa = false;
const cookieName = getCookieName('laquesis');
const cookieNameFf = getCookieName('laquesisff');
const cookieNameSu = getCookieName('laquesissu');
const cookieNameQa = getCookieName('laquesisqa');
/**
 * Map of active surveys with their triggers
 * @type {Record<string, Object>}
 */
export let listOfActiveSurveys = {};

/**
 * Map of active surveys with their type
 * FIXME: merge with active surveys somehow
 */
export let activeSurveysTypes = {};

// Link event
let isLinkEvent = false;

// QA
let qa = {
  enable: false,
  icon: true,
  style: true,
  expires: 2 * 60,
};

/**
 * Fetches fresh definitions
 */
export function fetchDefinitions() {
  const params = {};

  params.sl = currentSessionLong;
  if (userId) {
    if (typeof userId === 'string') {
      userId = userId.toLowerCase();
    }
    params.ui = userId;
  }
  params.cc = hydraConfig.params.cC;

  if (ninjaConfig.platform === 'm') {
    params.ch = 'm';
  } else if (ninjaConfig.platform === 'd') {
    params.ch = 'd';
  } else {
    params.ch = 'w';
  }

  params.br = hydraConfig.params.bR || 'olx';

  const url = `${SPLITTER_CDN}/assign?${objectToQueryString(params)}`;

  return ajaxCallPromise('get', url);
}

export function reloadLaquesisDefinitions() {
  return fetchDefinitions()
    .then(data => processDefinition(data))
    .catch(error => trackError('fetchDefinitionsError', 'Laquesis', 'reloadLaquesisDefinitions', error));
}

// Internal function - Init the tracker
export async function init() {
  let qaMode = false;
  let now = null;
  let mustRefresh = false;
  let queries = null;
  let split = null;
  let searchObject = {};
  let i = 0;
  let qaTestsCookie;
  let qaFlagsCookie;
  let qaSurveyId;

  if (navigator.cookieEnabled) {
    if (currentSessionLong) {
      // QA Config
      qa = {
        enable: false,
        icon: true,
        style: true,
        expires: 2 * 60,
      };
      if (ninjaConfig && ninjaConfig.laquesisQa) {
        if (ninjaConfig.laquesisQa.enable) {
          qa.enable = Boolean(ninjaConfig.laquesisQa.enable);
        }
        if (ninjaConfig.laquesisQa.icon) {
          qa.icon = Boolean(ninjaConfig.laquesisQa.icon);
        }
        if (ninjaConfig.laquesisQa.style) {
          qa.style = Boolean(ninjaConfig.laquesisQa.style);
        }
        if (ninjaConfig.laquesisQa.expires) {
          qa.expires = parseInt(ninjaConfig.laquesisQa.expires.toString(), 10);
        }
        if (ninjaConfig.laquesisQa.experiments) {
          qa.experiments = ninjaConfig.laquesisQa.experiments;
        }
        if (ninjaConfig.laquesisQa.flags) {
          qa.flags = ninjaConfig.laquesisQa.flags;
        }
      }

      // Set userId on the init
      if (ninjaConfig && ninjaConfig.userId) {
        userId = ninjaConfig.userId;
      }

      try {
        queries = window.location.search.replace(/^\?/, '').split('&');
        for (i = 0; i < queries.length; i = i + 1) {
          split = queries[i].split('=');
          searchObject[split[0]] = split[1];
        }

        if (undefined !== searchObject.laquesisqahash) {
          split = atob(deucex(searchObject.laquesisqahash)).split('!');
          if (split.length <= 4) {
            qaTestsCookie = split[0];
            // qaEnvironment = split[1];
            qaFlagsCookie = split[2];
            qaSurveyId = split[3];

            if (qaTestsCookie) {
              // Fixed list of experiments
              localTestsCookieValue = qaTestsCookie.toLowerCase();
              // Save in the cookie to enable backend experiments
              cookieStorage.set(cookieName, localTestsCookieValue, {
                expires: getCookieExpirationDate(qa.expires, 'min'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }

            if (qaFlagsCookie) {
              // Fixed list of flags
              localFlagsCookieValue = qaFlagsCookie.toLowerCase();
              // Save in the cookie to enable backend flags
              cookieStorage.set(cookieNameFf, localFlagsCookieValue, {
                expires: getCookieExpirationDate(qa.expires, 'min'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }

            if (qaSurveyId) {
              isLaquesisQa = true;

              // show survey with timeout
              const data = await getSurveyData(qaSurveyId);
              readEventsForHash(data);
            }

            if (qaTestsCookie || qaFlagsCookie || qaSurveyId) {
              // qaMode true so no refresh
              qaMode = true;
              // Disable the QA menu
              qa.enable = false;
              // Flag that it is in QAMODE so the refresh will not work
              cookieStorage.set(cookieNameQa, 1, {
                expires: getCookieExpirationDate(qa.expires, 'min'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }
          }
        }
      } catch (e) {
        return false;
      }

      if (ninjaConfig.environment !== 'production') {
        if (qa.enable === true) {
          if (undefined !== qa.experiments) {
            // Fixed list of experiments
            localTestsCookieValue = qa.experiments.toLowerCase();
            // Save in the cookie to enable backend experiments
            cookieStorage.set(cookieName, localTestsCookieValue, {
              expires: getCookieExpirationDate(qa.expires, 'min'),
              path: '/',
              domain: ninjaConfig.cookieDomain,
            });
            // qaMode true so no refresh
            qaMode = true;
            // Disable the QA menu
            qa.enable = false;
          }
          if (undefined !== qa.flags) {
            // Fixed list of flags
            localFlagsCookieValue = qa.flags.toLowerCase();
            // Save in the cookie to enable backend flags
            cookieStorage.set(cookieNameFf, localFlagsCookieValue, {
              expires: getCookieExpirationDate(qa.expires, 'min'),
              path: '/',
              domain: ninjaConfig.cookieDomain,
            });

            // qaMode true so no refresh
            qaMode = true;
            // Disable the QA menu
            qa.enable = false;
          }
        }
      }

      if (cookieStorage.get(cookieNameQa)) {
        // If the laquesisqa is still there

        // show survey in QA mode
        isLaquesisQa = true;

        // do not refresh
        qaMode = true;

        // Disable the QA menu
        qa.enable = false;
      }

      // Read the definition only ones
      if (!localTestsCookieValue) {
        localTestsCookieValue = cookieStorage.get(cookieName);
      }
      if (!localFlagsCookieValue) {
        localFlagsCookieValue = cookieStorage.get(cookieNameFf);
        if (!localFlagsCookieValue) {
          localFlagsCookieValue = cookieStorage.get('laquesis_ff');
        }
      }
      if (!localSurveysCookieValue) {
        localSurveysCookieValue = cookieStorage.get(cookieNameSu);
      }

      // Read the status
      lqDefinition = getLQDefinition();

      if (Object.keys(lqDefinition).length === 0) {
        // Refresh because there is no cookie yet
        mustRefresh = true;
      } else {
        // Check the expired time
        now = getNow();
        if (lqDefinition.nextRefresh - now < 0) {
          // Refresh because the definition is expired
          mustRefresh = true;
        }

        // check if there's a mismatch between saved definitions and current status
        if (lqDefinition.isDefinitionsForUserId && !ninjaConfig.userId) {
          // we have userId definitions, but ninja is not initialized with userId => fetch sl definitions
          mustRefresh = true;
          lqDefinition.isDefinitionsForUserId = false;
        } else if (!lqDefinition.isDefinitionsForUserId && ninjaConfig.userId) {
          // we have sl definitions, but ninja is not initialized with userId => fetch userId definitions
          mustRefresh = true;
          lqDefinition.isDefinitionsForUserId = true;
        }
      }

      if (mustRefresh && qaMode === false) {
        await reloadLaquesisDefinitions();
      } else {
        if (localTestsCookieValue) {
          parseLaquesisCookie(localTestsCookieValue);

          if (cookieFromLq) {
            // New user, the cookie and definition comes from the backend
            // cookieFromLq = false; // TODO:
            // Fix for some servers that quotes the cookie when contains @ :: Overwrite laquesis without quotes
            if (localTestsCookieValue) {
              localTestsCookieValue = localTestsCookieValue.replace(/^"(.+)"$/, '$1');
              cookieStorage.set(cookieName, localTestsCookieValue, {
                expires: getCookieExpirationDate(1, 'year'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }
            trackTestsAssignment();
          }
        }

        if (localFlagsCookieValue) {
          parseLaquesisFfCookie(localFlagsCookieValue);
        }

        // if (localSurveysCookieValue) {
        parseLaquesisSuCookie(localSurveysCookieValue);
        // }
      }

      // Watch the laquesis cookie
      if (initPushed === false) {
        initPushed = true;

        // expose functions to `window`
        setupWindow();

        // FIXME: should wait for processDefinition?
        // Call function when laquesis is loaded and ready to use
        if (undefined !== window.laquesisFunctionsCallback || typeof window.laquesisFunctionsCallback === 'function') {
          window.laquesisFunctionsCallback.call(null);
        }

        // Setup laquesisResults
        window.laquesisResults = window.laquesisResults || [];

        // Overwrite function push
        currentLqPush = window.laquesisResults.push;
        window.laquesisResults.push = function (params) {
          let result = currentLqPush.apply(window.laquesisResults, [params]);

          checkResults();

          return result;
        };

        // Set internal queue
        if (!window.laquesisQueue) {
          window.laquesisQueue = [];
        }
        window.laquesisQueue.push = function (func) {
          if (typeof func === 'function') {
            func();
          }
        };
        while (window.laquesisQueue.length > 0) {
          window.laquesisQueue.push(window.laquesisQueue.shift());
        }

        checkResults();
      }

      // FIXME: why we need this to run every 5 min?
      // Check the status again every 5 minutes
      setTimeout(init, 1000 * 60 * 5);
    } else {
      // The session_long do not exists yet, retry 5 times
      if (retries < 5) {
        retries = retries + 1;
        setTimeout(init, 500);
      }
    }
  }
  return true;
}

// Expose functionality to `window`
export function setupWindow() {
  // Expose function
  window.isVariantEnabled = function (testName, variantName, delayTracking, trackOrder) {
    return isVariantEnabled(testName, variantName, delayTracking, trackOrder);
  };

  // Expose function
  window.getLaquesisVariant = function (testName, delayTracking, trackOrder) {
    return getLaquesisVariant(testName, delayTracking, trackOrder);
  };

  // Expose function
  window.isFeatureEnabled = function (flagName) {
    return isFeatureEnabled(flagName);
  };

  // Expose function
  window.laquesisSetUserId = function (id) {
    return laquesisSetUserId(id);
  };

  // Expose function
  window.laquesisDropUserId = function () {
    return laquesisDropUserId();
  };

  // Expose function
  window.laquesisShowSurvey = function (surveyId) {
    if (isSurveyAvailable(surveyId, listOfActiveSurveys, activeSurveysTypes)) {
      isLaquesisQa = false;
      return showSurvey(surveyId, null, { result: `Manual trigger - window.laquesisShowSurvey ${surveyId}` });
    }
    return window.Promise.reject('Survey not available, already shown or in lockout period');
  };

  // Expose function
  window.isSurveyAvailable = function (surveyId) {
    return isSurveyAvailable(surveyId, listOfActiveSurveys, activeSurveysTypes);
  };

  if (ninjaConfig.environment !== 'production') {
    if (qa.enable === true) {
      // Call laquesisqa.js as external file to do not load it on production
      loadScript(LAQUESIS_QA_URL, 'laquesisqa', function () {
        loadScript(JSQR_URL, 'laquesisqr', function () {
          // @ts-ignore
          window.laquesisqa(
            {
              listOfActiveTests: listOfActiveTests,
              listOfActiveFlags: listOfActiveFlags,
              qa: qa,
              params: hydraConfig.params,
            },
            ninjaConfig
          );
        });
      });
    }

    // Expose function
    window.laquesisShowSurveyByLink = function (link) {
      isLaquesisQa = true;
      return showSurvey(0, link);
    };
  } else if (ninjaConfig.siteUrl === 'www.console-stg.data.olx.org' || ninjaConfig.siteUrl === 'www.console.data.olx.org') {
    // Expose function
    window.laquesisShowSurveyByLink = function (link) {
      isLaquesisQa = true;
      return showSurvey(0, link);
    };
  }

  window.laquesisGetActive = function () {
    // return copy of current state
    return {
      experiments: JSON.parse(JSON.stringify(listOfActiveTests)),
      flags: JSON.parse(JSON.stringify(listOfActiveFlags)),
    };
  };
}

// Collect the data from the API
export function processDefinition(newDefinition) {
  let config = null;
  let newCookieValue;
  let newFfCookieValue;
  let newSuCookieValue;
  let now = null;
  let testCookie = null;

  if (newDefinition) {
    if (newDefinition === '') {
      // this is tracked in error handler of the previous call
      // trackError('fetchDefinitionsError', 'Laquesis', 'processDefinition', 'Unable to connect');
    }
    config = JSON.parse(newDefinition);
  }

  if (config) {
    newCookieValue = parseTestsJson(config.tests);
    newFfCookieValue = parseFlagsJson(config.flags);
    newSuCookieValue = parseSurveysJson(config.surveys);

    // Set the next refresh
    now = getNow();
    lqDefinition.nextRefresh = now + parseInt(config.config.next_update_foreground_in_minutes, 10) * 60;
    writeLQDefinition(lqDefinition);

    // Track and store the assignment if it is different
    if (localTestsCookieValue !== newCookieValue) {
      // Save the new cookie value
      localTestsCookieValue = newCookieValue;
      cookieStorage.set(cookieName, newCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });

      // Track the assignment
      trackTestsAssignment();
      triggerCustomEvent(EVENTS.LAQUESIS_EXP_ASSIGNMENT, { experiments: newCookieValue });
    }

    // Store the flags if it is different
    if (localFlagsCookieValue !== newFfCookieValue) {
      // Save the new cookie value
      localFlagsCookieValue = newFfCookieValue;
      testCookie = cookieStorage.get('laquesis_ff');
      if (testCookie) {
        // Remove old cookie
        cookieStorage.remove('laquesis_ff', {
          path: '/',
          domain: ninjaConfig.cookieDomain,
        });
      }
      cookieStorage.set(cookieNameFf, newFfCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });

      // Track the assignment
      trackFlagsAssignment();
      triggerCustomEvent(EVENTS.LAQUESIS_FLAG_ASSIGNMENT, { flags: newFfCookieValue });
    }

    // Store the surveys if it is different
    if (localSurveysCookieValue !== newSuCookieValue) {
      // Save the new cookie value
      localSurveysCookieValue = newSuCookieValue;
      cookieStorage.set(cookieNameSu, newSuCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });
    }

    // Call function when assign is loaded and ready to use. @deprecated
    if (window.laquesisAssignCallback || typeof window.laquesisAssignCallback === 'function') {
      window.laquesisAssignCallback.call(null);
    }

    triggerCustomEvent(EVENTS.LAQUESIS_READY, null, document);
  }
}

export function parseTestsJson(tests) {
  let result = '';

  tests.sort((a, b) => a.tN.localeCompare(b.tN));
  listOfActiveTests = [];

  for (let i = 0; i < tests.length; i += 1) {
    tests[i].tN = tests[i].tN.toLowerCase();
    tests[i].vN = tests[i].vN.toLowerCase();
    result += TEST_DIVIDER + tests[i].tN + VARIANT_DIVIDER + tests[i].vN;
    listOfActiveTests[tests[i].tN] = tests[i].vN;
  }

  return result.substring(1);
}

export function parseFlagsJson(flags) {
  let result = '';

  flags.sort((a, b) => a.tN.localeCompare(b.tN));
  listOfActiveFlags = [];
  for (let i = 0; i < flags.length; i += 1) {
    flags[i].tN = flags[i].tN.toLowerCase();
    result += TEST_DIVIDER + flags[i].tN;
    listOfActiveFlags[flags[i].tN] = 1;
  }

  return result.substr(1);
}

export function parseSurveysJson(surveys) {
  let result = '';
  let eventName = '';
  let tmpCond = null;
  let conditions = {};
  let lqDefinitionLocal = [];
  let lqDefinitionTmp = [];
  const withLocalStorage = storageAvailable();

  surveys.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));

  lqDefinition = getLQDefinition();
  if (lqDefinition?.showedSurveys) {
    lqDefinitionTmp = lqDefinition.showedSurveys.split(TEST_DIVIDER);
  }

  listOfActiveSurveys = {};
  activeSurveysTypes = {};

  for (const survey of surveys) {
    if (survey.triggers) {
      if (survey.triggers.length === 0) {
        conditions[survey.id] = {};
        listOfActiveSurveys[''] = listOfActiveSurveys[''] || [];
        listOfActiveSurveys[''].push({
          id: survey.id,
          cond: [],
        });
      } else {
        for (const trigger of survey.triggers) {
          result += TEST_DIVIDER + survey.id;

          if (trigger.event_name) {
            eventName = trigger.event_name;
            tmpCond = trigger.conditions || [];

            // TODO: Remove this part alltogether and always check withLocalStorage
            if (tmpCond.length === 0) {
              result += VARIANT_DIVIDER + eventName + '|0';
            } else if (withLocalStorage) {
              result += VARIANT_DIVIDER + eventName + '|1';
            }

            // save all surveys, even those with empty conditions
            if (undefined === listOfActiveSurveys[eventName]) {
              listOfActiveSurveys[eventName] = [];
            }

            if (undefined === conditions[survey.id]) {
              conditions[survey.id] = {};
            }

            listOfActiveSurveys[eventName].push({
              cond: tmpCond,
              id: survey.id,
            });
            conditions[survey.id][eventName] = tmpCond;
          }
        }
      }
    }

    activeSurveysTypes[survey.id] = survey.type === 'feedback';

    // Clean up the showed surveys that are not active anymore
    if (lqDefinitionTmp.includes(survey.id.toString())) {
      lqDefinitionLocal.push(survey.id);
    }
  }

  // Store conditions by survey and event
  if (withLocalStorage) {
    window.localStorage.setItem(LQ_SURVEY_TRIGGERS_STORAGE_KEY, JSON.stringify(conditions));
    window.localStorage.setItem(LQ_SURVEY_TYPES_STORAGE_KEY, JSON.stringify(activeSurveysTypes));
  }

  lqDefinition.showedSurveys = lqDefinitionLocal.join(TEST_DIVIDER);
  writeLQDefinition(lqDefinition);

  return result.substring(1);
}

// Return if the testName with that variantValue is enable or not for this user
export function isVariantEnabled(testName, variantValue, delayTracking, trackOrder) {
  let foundTest = false;
  let testNameLower = testName.toLowerCase();
  let variantValueLower = variantValue.toLowerCase();

  if (!navigator.cookieEnabled) {
    return false;
  }

  if (undefined !== listOfActiveTests[testNameLower]) {
    if (listOfActiveTests[testNameLower] === variantValueLower) {
      foundTest = true;
      trackImpression(testNameLower, variantValueLower, delayTracking, trackOrder);
      triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
        experiment: testNameLower,
        variant: variantValueLower,
        isDelayedTracking: delayTracking,
        trackOrder: trackOrder,
      });
    }
  }

  return foundTest;
}

// Return the variant for testName or null
export function getLaquesisVariant(testName, delayTracking, trackOrder) {
  let variantValue = null;
  let testNameLower = testName.toLowerCase();

  if (!navigator.cookieEnabled) {
    return null;
  }

  if (undefined !== listOfActiveTests[testNameLower]) {
    variantValue = listOfActiveTests[testNameLower];
    trackImpression(testNameLower, variantValue, delayTracking, trackOrder);
    triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
      experiment: testNameLower,
      variant: variantValue,
      isDelayedTracking: delayTracking,
      trackOrder: trackOrder,
    });
    return variantValue;
  }

  return null;
}

// Return if the flagName is enable or not for this user
export function isFeatureEnabled(flagName) {
  if (!navigator.cookieEnabled) {
    return false;
  }

  return undefined !== listOfActiveFlags[flagName.toLowerCase()];
}

// Set userId
export function laquesisSetUserId(id) {
  if (id && userId !== id) {
    userId = id;
    reloadLaquesisDefinitions();
  }
}

// Drop userId
export function laquesisDropUserId() {
  if (userId) {
    userId = null;

    reloadLaquesisDefinitions();
  }
}

// Entry function - Parse the data in the array
export function checkResults() {
  function c(cookieValue) {
    parseLaquesisCookie(cookieValue);
  }

  for (const [index, data] of Object.entries(window.laquesisResults)) {
    if (typeof data === 'object' && !data.processed) {
      if (undefined !== data.newResult) {
        // Queue the call to the function
        window.laquesisResults[index].processed = true;
        window.laquesisQueue.push(c(data.newResult));
      }
    }
  }
}

// Track the tests assignment
export function trackTestsAssignment(delayTracking, trackOrder) {
  let testDefinition = Object.entries(listOfActiveTests)
    .map(([k, v]) => `"${k},${v}"`)
    .join(',');
  testDefinition = `[${testDefinition}]`;

  if (delayTracking) {
    ninjaConfig.dataLayerDelayed.push({
      trackOrder: trackOrder,
      trackEvent: ['test_assignment'],
      test_definition: testDefinition,
    });
  } else {
    cleanupTracking();
    ninjaConfig.dataLayer.push({
      trackEvent: ['test_assignment'],
      test_definition: testDefinition,
    });
  }
}

// Track the flags assignment
export function trackFlagsAssignment(delayTracking, trackOrder) {
  const flagDefinition =
    '[' +
    Object.keys(listOfActiveFlags)
      .map(e => `"${e}"`)
      .join(',') +
    ']';

  if (delayTracking) {
    ninjaConfig.dataLayerDelayed.push({
      trackOrder: trackOrder,
      trackEvent: ['flag_assignment'],
      flag_definition: flagDefinition,
    });
  } else {
    cleanupTracking();
    ninjaConfig.dataLayer.push({
      trackEvent: ['flag_assignment'],
      flag_definition: flagDefinition,
    });
  }
}

// Track the impression only one time per session per test
export function trackImpression(testName, variantValue, delayTracking, trackOrder) {
  let i;
  let trackedTests;
  let foundTrackedTest = false;
  let params = {};
  lqDefinition = getLQDefinition();

  if (!lqDefinition.currentSession || lqDefinition.currentSession !== currentSession) {
    // If there is no session or is different, start from 0
    lqDefinition.currentSession = currentSession;
    lqDefinition.trackedExperiments = ''; // Tracked tests
  } else {
    // If the session is the same, find the test
    if (lqDefinition.trackedExperiments) {
      trackedTests = lqDefinition.trackedExperiments.split(TEST_DIVIDER);
      for (i = 0; i < trackedTests.length; i += 1) {
        if (trackedTests[i] === testName) {
          foundTrackedTest = true;
          break;
        }
      }
    }
  }

  // Track the impression only one time per session
  if (!foundTrackedTest) {
    // Add the test and save the cookie
    if (!lqDefinition.trackedExperiments || lqDefinition.trackedExperiments.length === 0) {
      lqDefinition.trackedExperiments = testName;
    } else {
      lqDefinition.trackedExperiments += TEST_DIVIDER + testName;
    }
    writeLQDefinition(lqDefinition);

    // Finally track

    if (delayTracking) {
      params = {
        trackOrder: trackOrder,
        trackEvent: ['test_impression'],
        test_name: testName,
        test_variation: variantValue,
      };

      // Once user has interacted with the page vitals are out of scope -> do not send them anymore
      if (!hasUserInteractedWithPage) {
        // needed for web vitals to be linked to experiments
        params.web_vital_page = firstTrackPage;
      }

      ninjaConfig.dataLayerDelayed.push(params);
    } else {
      cleanupTracking();

      params = {
        trackEvent: ['test_impression'],
        test_name: testName,
        test_variation: variantValue,
      };

      if (!hasUserInteractedWithPage) {
        // needed for web vitals to be linked to experiments
        params.web_vital_page = firstTrackPage;
      }

      ninjaConfig.dataLayer.push(params);
    }
  }
}

// Remove all the test properties
export function cleanupTracking() {
  let length = ninjaConfig.dataLayer.length;
  let i;

  for (i = 0; i < length; i = i + 1) {
    if (
      undefined !== ninjaConfig.dataLayer[i].test_definition ||
      undefined !== ninjaConfig.dataLayer[i].test_name ||
      undefined !== ninjaConfig.dataLayer[i].test_variation ||
      undefined !== ninjaConfig.dataLayer[i].flag_definition
    ) {
      ninjaConfig.dataLayer.splice(i, 1);
      i = length;
    }
  }
}

// Track
export function trackSurvey(eventName, survey_id, survey_page_id, question_id, question_value) {
  let url;
  let params = {};

  // Finish current survey
  if (eventName !== 'survey_show' && eventName !== 'survey_push_show') {
    if (activeSurvey === true) {
      activeSurvey = false;
    }
  }

  // Path
  url = getProtocol() + HYDRA_HOST + hydraConfig.survey_path;

  // Properties
  params.eN = eventName;
  params.sl = currentSessionLong;
  params.s = currentSession;
  params.survey_id = survey_id;
  params.survey_type = activeSurveysTypes?.[survey_id] ? 'feedback' : 'default';
  params.survey_page_id = survey_page_id;
  if (undefined !== question_id) {
    params.survey_question_id = question_id;
  }
  if (undefined !== question_value) {
    params.survey_question_value = question_value;
  }

  // Config values, countries and regions
  params.cC = hydraConfig.params.cC;
  params.bR = hydraConfig.params.bR;

  if (ninjaConfig.platform === 'm') {
    params.cH = ChannelName.Mobile;
  } else if (ninjaConfig.platform === 'd') {
    params.cH = ChannelName.Desktop;
  } else if (undefined !== currentPlatform) {
    params.cH = currentPlatform;
  } else {
    params.cH = 'w';
  }

  // Matrix Version
  if (undefined !== regionConfig.version) {
    params.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    params.env = 'dev';
  }

  // Timestamp
  params.t = new Date().getTime();

  trackWithBeacon(url, params);
}

// Read and parse the test cookie
export function parseLaquesisCookie(cookie) {
  let listOfTest;
  let i;
  let nameValue;
  let valueFlag;
  let cookieValue = cookie;

  // Fix for some servers that quotes the cookie when contains @ :: Use value without quotes
  if (cookieValue) {
    cookieValue = cookieValue.replace(/^"(.+)"$/, '$1');
  }

  listOfTest = cookieValue.split(TEST_DIVIDER);
  for (i = 0; i < listOfTest.length; i += 1) {
    nameValue = listOfTest[i].split(VARIANT_DIVIDER);
    if (nameValue.length === 2) {
      valueFlag = nameValue[1].split(VALUE_DIVIDER);
      if (valueFlag.length === 2) {
        // Read the set-cookie from the server
        if (valueFlag[1] === 't') {
          trackImpression(nameValue[0], valueFlag[0]);
          triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
            experiment: nameValue[0],
            variant: valueFlag[0],
            isDelayedTracking: false,
            trackOrder: undefined,
          });
          if (undefined === listOfActiveTests[nameValue[0]]) {
            listOfActiveTests[nameValue[0]] = valueFlag[0];
          }
        }
      } else {
        // Read the normal cookie
        if (undefined === listOfActiveTests[nameValue[0]]) {
          listOfActiveTests[nameValue[0]] = valueFlag[0];
        }
      }
    }
  }
}

// Read and parse the flags cookie
export function parseLaquesisFfCookie(cookieValue) {
  let listOfFlags;
  let i;

  listOfFlags = cookieValue.split(TEST_DIVIDER);
  for (i = 0; i < listOfFlags.length; i += 1) {
    // Read the normal cookie
    if (undefined === listOfActiveFlags[listOfFlags[i]]) {
      listOfActiveFlags[listOfFlags[i]] = 1;
    }
  }
}

// Read and parse the surveys cookie
export function parseLaquesisSuCookie(cookieValue = '') {
  let listOfSurveys;
  let i;
  let surveyValue;
  let surveyId;
  let triggers = [];
  let eventName;
  // let conditionsFlag;
  let conditions = {};
  let tmpCond = null;
  let withLocalStorage = false;
  let json = null;

  // Get all the conditions if localStorage exists
  withLocalStorage = storageAvailable();
  if (withLocalStorage) {
    json = window.localStorage.getItem(LQ_SURVEY_TRIGGERS_STORAGE_KEY);

    if (json) {
      // old versions have it encoded
      if (json.startsWith(encodeURIComponent('{')) && json.endsWith(encodeURIComponent('}'))) {
        json = decodeURIComponent(json);
        window.localStorage.setItem(LQ_SURVEY_TRIGGERS_STORAGE_KEY, json);
      }

      conditions = JSON.parse(json);
    }

    if (window.localStorage[LQ_SURVEY_TYPES_STORAGE_KEY]) {
      activeSurveysTypes = JSON.parse(window.localStorage.getItem(LQ_SURVEY_TYPES_STORAGE_KEY));
    }
  }

  listOfSurveys = cookieValue.split(TEST_DIVIDER);
  for (i = 0; i < listOfSurveys.length; i += 1) {
    surveyValue = listOfSurveys[i].split(VARIANT_DIVIDER);
    if (surveyValue.length === 2) {
      surveyId = surveyValue[0];
      triggers = surveyValue[1].split(VALUE_DIVIDER);
      if (triggers.length > 1) {
        eventName = triggers[0];
        // conditionsFlag = triggers[1];

        if (withLocalStorage) {
          if (undefined === listOfActiveSurveys[eventName]) {
            listOfActiveSurveys[eventName] = [];
          }
          if (conditions && undefined !== conditions[surveyId] && undefined !== conditions[surveyId][eventName]) {
            tmpCond = conditions[surveyId][eventName];
          } else {
            tmpCond = [];
          }

          listOfActiveSurveys[eventName].push({
            cond: tmpCond,
            id: surveyId,
          });
        }
        // if (conditionsFlag === '0') {
        //   if (undefined === listOfActiveSurveys[eventName]) {
        //     listOfActiveSurveys[eventName] = [];
        //   }
        //   listOfActiveSurveys[eventName].push({
        //     cond: [],
        //     id: surveyId
        //   });
        // } else if (withLocalStorage) {
        //   if (undefined === listOfActiveSurveys[eventName]) {
        //     listOfActiveSurveys[eventName] = [];
        //   }
        //   if (undefined !== conditions[surveyId] && undefined !== conditions[surveyId][eventName]) {
        //     tmpCond = conditions[surveyId][eventName];
        //   }
        //   listOfActiveSurveys[eventName].push({
        //     cond: tmpCond,
        //     id: surveyId
        //   });
        // }
      }
    }
  }
}

// Check if support localStorage
export function storageAvailable() {
  let storage;
  try {
    let x = '__storage_test__';
    storage = window.localStorage;
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

// Entry function - Track a page
export function trackPage(params) {
  let eventData = [];
  let eventName;
  let newValues;

  onHitSentOk();
  if (initPushed === true) {
    // eventName, (trackPage) and eventType
    if (params.eventData.category) {
      if (params.eventData.category) {
        eventData.push(params.eventData.category);
      }
      if (params.eventData.action) {
        eventData.push(params.eventData.action);
      }
      if (params.eventData.label) {
        eventData.push(params.eventData.label);
      }
      if (params.eventData.value) {
        eventData.push(params.eventData.value);
      }

      eventName = eucex(convertString(eventData.join('_')));
      newValues = makeMapping('H', 'trackEvent', eventName);
      if (newValues.key === false || newValues.value === false) {
        return false;
      }
      eventName = newValues.value;
    }
    if (params.pageName) {
      // Add original trackPage
      newValues = makeMapping('H', 'trackPage', params.pageName);
      if (newValues.key === false || newValues.value === false) {
        return false;
      }
      eventName = eucex(newValues.value);
    }

    if (undefined !== listOfActiveSurveys[eventName]) {
      for (const survey of listOfActiveSurveys[eventName]) {
        const triggerResult = canShowSurvey(survey.id, params, survey.cond, listOfActiveSurveys, activeSurveysTypes, isLaquesisQa);

        if (triggerResult.matches) {
          isLaquesisQa = false;
          triggerResult.event = eventName;
          triggerResult.active_surveys = listOfActiveSurveys[eventName];

          debugSurvey(SURVEY_TRIGGER_MATCH, survey.id, {
            trigger_result: triggerResult || 'Missing trigger match!',
          });
          // survey can be shown -> no point to continue checking other surveys
          if (showSurvey(survey.id)) {
            break;
          }
        }
      }
    }
  } else {
    // If Laquesis is not started, retry 5 times every half second
    if (trackPageRetries < 5) {
      trackPageRetries = trackPageRetries + 1;
      setTimeout(() => trackPage(params), 500);
      return false;
    }
  }

  return true;
}

// Entry function - Track an event
export function trackEvent(params) {
  return trackPage(params);
}

// Entry function - Track a link event
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

// Internal function - Callback after finish one hit
export function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}

export async function showSurvey(surveyId, link) {
  // if (!isLaquesisQa) {
  //   // Check if the surveys was already shown
  //   if (!isSurveyAvailable(surveyId)) {
  //     return false;
  //   }

  //   surveyLink = null;
  // }

  // Another survey is running
  if (activeSurvey) {
    debugSurvey(SURVEY_ALREADY_ACTIVE, surveyId, {
      active_survey: `${surveyId} skipped, other already active`,
    });
    return window.Promise.reject(false);
  }
  activeSurvey = true;

  counter = getNow();

  // forward the data and the trigger results
  try {
    const data = await getSurveyData(surveyId, link);
    renderSurvey(data);
  } catch (e) {
    activeSurvey = false;
    debugSurvey(SURVEY_LOAD_ERROR, surveyId, {
      error: e,
    });

    return window.Promise.reject(false);
  }

  return window.Promise.resolve(true);
}

export function readEventsForHash(jsonSurveyData) {
  let surveyData;
  let newSuCookieValue;

  if (jsonSurveyData === null || jsonSurveyData === '') {
    trackError('fetchSurveyError', 'Survey', 'renderSurvey', 'Unable to connect');
    return;
  }

  surveyData = JSON.parse(jsonSurveyData);
  newSuCookieValue = parseSurveysJson([surveyData]);

  // Store the surveys if it is different
  if (localSurveysCookieValue !== newSuCookieValue) {
    // Save the new cookie value
    localSurveysCookieValue = newSuCookieValue;
    cookieStorage.set(cookieNameSu, newSuCookieValue, {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: ninjaConfig.cookieDomain,
    });
  }
}

export function renderSurvey(jsonSurveyData, triggerResult) {
  if (jsonSurveyData === null || jsonSurveyData === '') {
    trackError('fetchSurveyError', 'Survey', 'renderSurvey', 'Unable to connect');
    return;
  }

  const continueRender = () => {
    /**
     * @type {import('../../laquesis/survey/surveyActions').surveyData}
     */
    const surveyData = JSON.parse(jsonSurveyData);
    let delayRender = 3;
    const now = getNow();

    if (surveyData.config) {
      if (undefined !== surveyData.config.delay_render_in_sec) {
        delayRender = surveyData.config.delay_render_in_sec;
      }

      // Render with delay
      // if (!isLaquesisQa) {

      const timeLoading = now - counter;
      const timeRemaining = Math.max(0, delayRender - timeLoading);
      if (timeRemaining > 0) {
        // delayRender is in seconds
        setTimeout(() => renderSurvey(jsonSurveyData, triggerResult), timeRemaining * 1000);
        return;
      }
      // }
      debugSurvey(SURVEY_RENDER_START, surveyData.id, {
        delay_render_in_sec: delayRender,
      });
      // Use altered `trackSurvey` callback when we are in QA mode
      if (isLaquesisQa) {
        debugSurvey(SURVEY_RENDER, surveyData.id, {
          qa: true,
        });
        window.laquesisSurvey(surveyData, function (eventName) {
          // Finish current survey
          if (eventName !== 'survey_show' && eventName !== 'survey_push_show') {
            if (activeSurvey === true) {
              activeSurvey = false;
            }
          }
        });
      } else {
        debugSurvey(SURVEY_RENDER, surveyData.id, {
          qa: false,
        });
        window.laquesisSurvey(surveyData, trackSurvey);
      }

      if (isLaquesisQa) {
        isLaquesisQa = false; // reset QA mode
      } else {
        // Mark this survey as shown and save lqDefinition
        markSurveyAsShown(surveyData);

        const lqDefinition = getLQDefinition();
        debugSurvey(SURVEY_MARK_SHOWN, surveyData.id, {
          lock_until: lqDefinition.nextSurveyShow,
          shown_to_user: lqDefinition.showedSurveys,
        });
      }
    }
  };

  // ninjaConfig.asyncSurveyStyles = true;
  // ninjaConfig.surveyTheme = 'cee';
  // debugger;
  if (ninjaConfig.asyncSurveyStyles) {
    const theme = ninjaConfig.surveyTheme || 'default';
    const filename = `${LAQUESIS_SURVEY_CSS_FILENAME}-${theme}.css`;

    loadCSS(`${NINJA_CDN}/${filename}`, 'laquesisSurveyStyles', continueRender);
  } else {
    continueRender();
  }
}

export function getIdentifier() {
  return userId || currentSessionLong;
}
