import { LAQUESIS_SURVEY_JS_URL, SPLITTER_CDN, TEST_DIVIDER } from '../../const';
import { cookieStorage } from '../../cookies';
import { getDefaultParams } from '../../core/params';
import { ajaxCallPromise, deucex, getCurrentPath, getNow, loadScript, objectToQueryString } from '../../utils';
import { writeLQDefinition, getLQDefinition } from '../../laquesis/definitions';
import hydraConfig from '../../config/hydra';
import ninjaConfig from '../../config/ninja';
import { debugSurvey, SURVEY_LOAD } from './surveyDebug';

/**
 * @typedef {Object} TriggerResult
 * @prop {boolean} TriggerResult.matches
 * @prop {any[]} TriggerResult.results
 */

/**
 * Checks if a survey can be shown:
 * - params match triggers
 * - survey is available
 * - survey is not yet shown
 * - user is not in lockout from previous survey
 * @param {number} id
 * @param {Record<string, any>} params Current tracking params
 * @param {any[]} conditionGroups Survey trigger condition groups
 * @param {Record<string, any>} listOfActiveSurveys
 * @param {boolean} isLaquesisQa
 * @returns {TriggerResult}
 */
export function canShowSurvey(id, params, conditionGroups = [], listOfActiveSurveys = {}, activeSurveysTypes = {}, isLaquesisQa = false) {
  if (isLaquesisQa || isSurveyAvailable(id, listOfActiveSurveys, activeSurveysTypes)) {
    return checkConditionGroups(params, conditionGroups);
  }

  return { matches: false };
}

/**
 * Checks if a given survey's triggers match the current tracked params
 * @param {Record<string, any>} params Current tracking params
 * @param {any[]} conditionGroups Survey trigger condition groups
 * @returns {TriggerResult}
 */
export function checkConditionGroups(params, conditionGroups) {
  // surveys without any conditions can be triggered only with function call
  if (!Array.isArray(conditionGroups)) {
    return {
      matches: false,
    };
  }

  let allParams = params.customParams;
  let defaultParams = getDefaultParams(params);

  // Add identifiers
  allParams.sl = params.sessionParams.sessionLong;
  allParams.s = params.sessionParams.session;
  allParams.cl = params.sessionParams.sessionCountLong;
  allParams.c = params.sessionParams.sessionCount;
  allParams.cp = deucex(getCurrentPath());
  allParams.cc = defaultParams.cC;
  allParams.br = defaultParams.bR;

  let results = conditionGroups.map(group => checkConditions(allParams, group));

  // Condition groups are grouped with AND, while separate conditions inside a group are with OR
  // all conditions must match
  return {
    matches: results.every(e => e.matches),
    results,
  };
}

/**
 * Checks the tracking params agains the conditions in a group
 * @param {Record<string, any>} params List of tracking properties and their values
 * @param {any[]} conditions Condition rule, defined by Console UI
 */
export function checkConditions(params, conditions) {
  let paramValue;
  let matchingCondition = {
    matches: false,
    c: undefined, // is cookie
    n: undefined, // name
    o: undefined, // operator
    v: undefined, // value
    value: undefined, // actual prop value
  };
  const getConditionLog = (cond, value) => ({
    matches: true,
    c: cond.c,
    n: cond.n,
    o: cond.o,
    v: cond.v,
    value,
  });

  // Conditions inside a group are grouped with OR -> we can return early if we find a single matching condition
  conditions.some(cond => {
    // Get the value using cookies or properties
    if (undefined !== cond.c && Boolean(cond.c)) {
      paramValue = cookieStorage.get(cond.n);
    } else {
      paramValue = params[cond.n];
    }
    if (cond.o === 'is_null') {
      if (undefined === paramValue || paramValue === null) {
        matchingCondition = getConditionLog(cond, paramValue);
        return true;
      }
    } else if (undefined !== paramValue && paramValue !== null) {
      // operator
      switch (cond.o) {
        case 'equals':
          if (paramValue.toString() === cond.v.toString()) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        case 'not_equals':
          if (paramValue.toString() !== cond.v.toString()) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        case 'is_not_null':
          if (paramValue !== null) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        // TODO: Why parsing to int? what about floats?
        case 'greater_than':
          if (parseInt(paramValue, 10) > parseInt(cond.v, 10)) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        case 'less_than':
          if (parseInt(paramValue, 10) < parseInt(cond.v, 10)) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        default:
          return false;
      }
    }

    return false;
  });

  return matchingCondition;
}

/**
 * Return if this surveys is ready to be shown for this user
 * @param {number} surveyId
 * @param {Record<string, any>} listOfActiveSurveys
 * @returns {boolean}
 */
export function isSurveyAvailable(surveyId, listOfActiveSurveys, activeSurveysTypes) {
  const found =
    Object.values(listOfActiveSurveys).some(surveys => surveys.some(s => s.id.toString() === surveyId.toString())) ||
    surveyId in activeSurveysTypes;

  // Check if the surveys was already shown and not in lockout period
  if (found) {
    // feedback survey -> ignore other rules
    if (activeSurveysTypes?.[surveyId]) {
      return true;
    }

    return !isSurveyShown(surveyId) && !isInLockoutPeriod(surveyId, activeSurveysTypes);
  }

  return false;
}

export function isInLockoutPeriod(surveyId, activeSurveysTypes) {
  const lqDefinition = getLQDefinition();
  const now = getNow();

  // True means the survey is "feedback" -> ignore lockout
  if (activeSurveysTypes?.[surveyId]) {
    return false;
  }

  return now <= lqDefinition.nextSurveyShow;
}

export function isSurveyShown(surveyId) {
  const lqDefinition = getLQDefinition();
  const definition = lqDefinition?.showedSurveys ? lqDefinition.showedSurveys.split(TEST_DIVIDER) : [];

  return definition.includes(surveyId.toString());
}

/**
 * @param {import('../../laquesis/survey/surveyActions').surveyData} survey
 */
export function markSurveyAsShown(survey) {
  const lqDefinition = getLQDefinition();
  const definition = lqDefinition?.showedSurveys ? lqDefinition.showedSurveys.split(TEST_DIVIDER) : [];
  const lockoutInSeconds = survey.config?.next_survey_allowed_in_sec || 0;

  if (!isSurveyShown(survey.id)) {
    definition.push(survey.id);

    // add to shown surveys
    lqDefinition.showedSurveys = definition.join(TEST_DIVIDER);

    // feedback surveys ignore lockout period
    if (survey.type === 'default') {
      // Store the next time to show another survey
      lqDefinition.nextSurveyShow = getNow() + lockoutInSeconds;
    }

    writeLQDefinition(lqDefinition);
  }
}

export function getSurveyData(surveyId, link) {
  return new window.Promise(resolve => {
    loadScript(LAQUESIS_SURVEY_JS_URL, 'laquesisSurvey', async () => {
      let suburl;
      let params = {};

      if (link) {
        suburl = link;
      } else {
        suburl = SPLITTER_CDN + '/survey';
        params.id = surveyId;
        params.cc = hydraConfig.params.cC;
        params.br = hydraConfig.params.bR;

        if (ninjaConfig.platform === 'm') {
          params.ch = 'm';
        } else if (ninjaConfig.platform === 'd') {
          params.ch = 'd';
        } else {
          params.ch = 'w';
        }
      }
      const url = suburl + '?' + objectToQueryString(params);
      const response = await ajaxCallPromise('get', url);

      debugSurvey(SURVEY_LOAD, surveyId, {
        splitter_url: url,
      });
      resolve(response);
    });
  });
}
