import hydraConfig from '../config/hydra';
import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { HYDRA_HOST, Trackers, trackingMap } from '../const';
import { cookieStorage, getCookieName } from '../cookies';
import { eucex, getCurrentPath, getProtocol } from '../utils';
import { getCustomParams } from './params';
import { checkIfDebugEligible, generateSession, randomFactor } from './session';
import trackers from './trackers';
import { getEventData, getHash, getHost, getInvite, getPageName, getReferrer, trackWithBeacon } from './utils';

// need this way of structure, because we invoke track methods by property name `ninja[trackMethod]`
let ninja = {};

ninja.trackers = trackers;

// Link events
ninja.linkCallBack = null;
ninja.linkCount = 0;
ninja.linkTotalCount = 0;
ninja.linkSetTimeout = null;
ninja.linkEventName = null;
ninja.trackerList = null;
ninja.pluginList = null;

export let currentSessionLong = null;
export let currentSession = null;
export let cookieFromLq = false;
export let currentTracker = 'start';

// Entry function - Parse the data in the dataLayer
ninja.checkParam = function () {
  /**
   *
   * @param {String} fn - Function to invoke
   * @param {String} parameter - Function parameter
   * @param {Record<string, any>} data - user parameters
   */
  const c = (fn, parameter, data) => {
    ninja[fn](parameter, data);
  };

  for (const data of ninjaConfig.dataLayer) {
    if (typeof data === 'object' && undefined === data.processed) {
      for (const functionName of ninjaConfig.specialNames) {
        if (functionName !== 'processed' && data[functionName]) {
          // Queue the call to the function
          data.processed = true;
          window.trackingQueue.push(c(functionName, data[functionName], data));
        }
      }
    }
  }
};

// Push function - Tracking a page view
ninja.trackPage = function (pageName, eventData) {
  const params = getParams(pageName, null, eventData);
  const trackerList = getTrackerList();

  for (const trackerKey of trackerList) {
    try {
      currentTracker = trackingMap[trackerKey];

      ninja.trackers[currentTracker].trackPage(params);
    } catch (error) {
      trackError('JAVASCRIPT_ERROR', currentTracker, 'trackPage', error);
    }
  }
};

// Push function - Tracking an event
ninja.trackEvent = function (params, eventData) {
  let eventParams = params;

  // Fix for send trackEvent as string instead of Array
  if (typeof eventParams === 'string') {
    eventParams = [eventParams];
  }

  const ninjaParams = getParams(null, eventParams, eventData);
  const trackerList = getTrackerList();

  for (const trackerKey of trackerList) {
    try {
      currentTracker = trackingMap[trackerKey];
      ninja.trackers[currentTracker].trackEvent(ninjaParams);
    } catch (error) {
      trackError('JAVASCRIPT_ERROR', currentTracker, 'trackEvent', error);
    }
  }
};

// Push function - Tracking a link event
ninja.trackLinkEvent = function (eventParams, eventData) {
  const trackerList = getTrackerList();
  const params = getParams(null, eventParams, eventData);

  try {
    if (ninja.linkCallBack !== null) {
      return;
    }
    if (undefined === params.customParams.linkCallBack || typeof params.customParams.linkCallBack !== 'function') {
      return;
    }
    ninja.linkCallBack = params.customParams.linkCallBack;
    ninja.linkEventName = eventParams;
    ninja.linkCount = 0;
    ninja.linkTotalCount = 0;
    delete params.customParams.linkCallBack;

    // First count how many
    ninja.linkTotalCount = trackerList.length;
    // Second trigger the track

    for (const trackerKey of trackerList) {
      currentTracker = trackingMap[trackerKey];
      ninja.trackers[currentTracker].trackLinkEvent(params);
    }

    ninja.linkSetTimeout = setTimeout(() => {
      ninja.linkCount = ninja.linkTotalCount;
      ninja.linkCallBack.call(null, ninja.linkEventName);
      ninja.linkTotalCount = 0;
      ninja.linkCallBack = null;
    }, 1000);
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'trackLinkEvent', error);
  }
};

ninja.trackPerformanceEvent = function (eventName, eventData) {
  // these events do not need any previous data
  const params = getParams(null, [eventName], eventData, true);
  const trackerList = getTrackerList();

  for (const trackerKey of trackerList) {
    try {
      currentTracker = trackingMap[trackerKey];

      if (typeof ninja.trackers[currentTracker].trackPerformanceEvent === 'function') {
        ninja.trackers[currentTracker].trackPerformanceEvent(params);
      }
    } catch (error) {
      trackError('JAVASCRIPT_ERROR', currentTracker, 'trackPerformanceEvent', error);
    }
  }
};

// Push function - Cleanup dataLayer
ninja.cleanup = function (callBack) {
  let continueRunning = true;

  try {
    while (continueRunning) {
      continueRunning = false;
      for (let i = 0; i < ninjaConfig.dataLayer.length; i = i + 1) {
        // gtag uses array-like objects to set id and consent. Ignore such entries
        if (Array.isArray(ninjaConfig.dataLayer[i]) || 0 in ninjaConfig.dataLayer[i]) {
          continue;
        }

        if (
          undefined === ninjaConfig.dataLayer[i].event ||
          (ninjaConfig.dataLayer[i].event.slice(0, 4) !== 'gtm.' && undefined !== ninjaConfig.dataLayer[i]['gtm.uniqueEventId'])
        ) {
          if (
            typeof ninjaConfig.dataLayer[i] === 'object' &&
            undefined === ninjaConfig.dataLayer[i].call &&
            undefined === ninjaConfig.dataLayer[i].dynx_itemid && // Fix for RO
            undefined === ninjaConfig.dataLayer[i].dynx_totalvalue && // Fix for RO
            undefined === ninjaConfig.dataLayer[i].dynx_pagetype // Fix for RO
          ) {
            continueRunning = undefined === ninjaConfig.dataLayer[i].cleanup;
            ninjaConfig.dataLayer.splice(i, 1);
            i = length;
          }
        }
      }
    }
    if (typeof callBack === 'function') {
      callBack.call();
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'cleanup', error);
  }
};

// Push function - GTM event
ninja.event = function (eventName) {
  try {
    if (ninjaConfig.callBack) {
      ninjaConfig.callBack.call(ninja, eventName);
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'event', error);
  }
};

/**
 *  Get the trackers list
 * @returns {string[]}
 */
export function getTrackerList() {
  if (ninja.trackerList === null) {
    ninja.trackerList = [Trackers.H];

    if (ninjaConfig.custom !== false && ninjaConfig.environment !== 'production') {
      if (ninjaConfig.custom) {
        for (const key of Object.keys(ninjaConfig.custom)) {
          if (trackers[key] && trackers[key].trackPage) {
            ninja.trackerList.push(key);
          }
        }
      }
    } else {
      // For Laquesis
      if (getPluginList().includes(Trackers.LQ)) {
        // insert at position 1 - just after hydra and before all 3rd party trackers
        ninja.trackerList.push(Trackers.LQ);
      }

      if (regionConfig.custom[ninjaConfig.siteUrl].trackers) {
        ninja.trackerList.push(...regionConfig.custom[ninjaConfig.siteUrl].trackers);
      } else {
        ninja.trackerList.push(...regionConfig.trackers);
      }
    }
  }

  return ninja.trackerList;
}

// Get the plugins list
export function getPluginList() {
  if (!ninja.pluginList) {
    if (ninjaConfig.plugins && ninjaConfig.environment !== 'production') {
      ninja.pluginList = [];
      for (const plugin of ninjaConfig.plugins) {
        ninja.pluginList.push(plugin);
      }
    } else {
      if (regionConfig.custom[ninjaConfig.siteUrl].plugins) {
        ninja.pluginList = [].concat(regionConfig.custom[ninjaConfig.siteUrl].plugins);
      } else {
        ninja.pluginList = [].concat(regionConfig.plugins);
      }
    }
  }
  return ninja.pluginList;
}

// Collect the finish call from the trackers
export function collectCalls() {
  ninja.linkCount = ninja.linkCount + 1;
  if (ninja.linkCount === ninja.linkTotalCount) {
    ninja.linkCallBack.call(null, ninja.linkEventName);
    ninja.linkCallBack = null;
    ninja.linkTotalCount = 0;
    clearTimeout(ninja.linkSetTimeout);
  }
}

// Manage the session cookie
export function getSessionParams() {
  let date;
  let now;
  let session;
  let sessionLong;
  let sessionCount;
  let sessionCountLong;
  let sessionExpired;
  let sessionExtra;
  let sessionValues;
  let cookieName;
  let cookieValue;
  let noCookie = false;
  let sessionParams;
  let isSessionChange = false;

  let debugInfo = [];

  debugInfo.push('Retrieving session params');

  try {
    if (navigator.cookieEnabled) {
      debugInfo.push('Cookies enabled. Checking onap cookie');
      date = new Date();
      now = Math.round(date.getTime() / 1000);
      cookieName = getCookieName('onap');
      sessionValues = (cookieStorage.get(cookieName) || '').match(/([a-z0-9]+)-([0-9]+)-([a-z0-9]+)-([0-9]+)-([0-9]+)-?(.*)?/);
      debugInfo.push('Cookie value (onap): ' + sessionValues);
      if (sessionValues && sessionValues.length > 0) {
        sessionLong = sessionValues[1];
        sessionCountLong = parseInt(sessionValues[2], 10);
        session = sessionValues[3];
        sessionCount = parseInt(sessionValues[4], 10);
        sessionExpired = sessionValues[5];
        sessionExtra = sessionValues[6] || null;

        if (sessionExpired - now > 0) {
          sessionCount = sessionCount + 1;
        } else {
          debugInfo.push('Session expired. Generating a new session');
          sessionCountLong = sessionCountLong + 1;
          session = generateSession();
          sessionCount = 1;
        }
      } else {
        // Check if there is a lqonap cookie
        sessionValues = cookieStorage.get(getCookieName('lqonap'));
        debugInfo.push('Cookie value (lqonap): ' + sessionValues);
        if (sessionValues && sessionValues.length > 0) {
          // Use the laquesis session_long
          sessionLong = sessionValues;
          sessionCountLong = 1;
          session = sessionLong;
          sessionCount = 1;
          sessionExtra = null;
          cookieFromLq = true;
        } else {
          debugInfo.push('Cookie not found. Generating a new sessionLong');
          // New user, create new session_long
          sessionLong = generateSession();
          sessionCountLong = 1;
          session = sessionLong;
          sessionCount = 1;
          sessionExtra = null;
        }
      }
      sessionExpired = now + 1800;
      cookieValue = sessionLong + '-' + sessionCountLong + '-' + session + '-' + sessionCount + '-' + sessionExpired;
      if (sessionExtra) {
        cookieValue = cookieValue + '-' + sessionExtra;
      }
      cookieValue = cookieValue.replace(/[^\w\-\=]/g, '');

      cookieStorage.set(cookieName, cookieValue, {
        expires: 360,
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });
    } else {
      debugInfo.push('Cookies disabled. Generating a new sessionLong');
      sessionLong = generateSession();
      sessionCountLong = 1;
      session = sessionLong;
      sessionCount = 1;
      noCookie = true;
    }

    isSessionChange = currentSession !== null && currentSession !== session;

    // if session has changed and it's not the first one, trigger callback
    if (isSessionChange && typeof window.ninjaSessionChangedCallback === 'function') {
      window.ninjaSessionChangedCallback.apply(null, [session, currentSession]);
    }
  } catch (error) {
    debugInfo.push('Retrieving cookie data failed: ' + error.message + '. Generating a new sessionLong');
    sessionLong = generateSession();
    sessionCountLong = 1;
    session = sessionLong;
    sessionCount = 1;
    noCookie = true;
  } finally {
    // trackDebugInfo(debugInfo.join('\n'));
  }

  sessionParams = {
    sessionLong: sessionLong,
    session: session,
    sessionCountLong: sessionCountLong,
    sessionCount: sessionCount,
  };

  if (noCookie) {
    sessionParams.noCookie = noCookie;
  }

  currentSessionLong = sessionLong;
  currentSession = session;

  // update exposed window props only when session changes
  if (isSessionChange) {
    // exposeWindowObject();
  }

  return sessionParams;
}

// Collect all the data available
export function getParams(pageName, eventParams, eventData, disablePropertyPropagation = ninjaConfig.disablePropertyPropagation) {
  return {
    invite: getInvite(),
    host: getHost(),
    hash: getHash(),
    referrer: getReferrer(),
    pageName: getPageName(pageName),
    eventData: getEventData(eventParams),
    customParams: getCustomParams(eventData, disablePropertyPropagation),
    sessionParams: getSessionParams(),
  };
}

// Track error
export function trackError(errorName, tracker, method, error) {
  const url = getProtocol() + HYDRA_HOST + hydraConfig.error_path;
  const props = {};
  let info;

  if (error) {
    if (error.description) {
      info = error.description;
    } else if (error.message) {
      info = error.message;
    } else {
      info = error;
    }
  } else {
    info = error;
  }

  // Path

  // Properties
  props.eN = eucex(errorName);
  props.sl = currentSessionLong;

  if (currentSession) props.s = currentSession;
  else props.s = '00000000000' + 'x' + randomFactor;
  if (tracker) props.tracker = eucex(tracker);
  if (method) props.method = eucex(method);
  props.info = eucex(info);

  // Config values, countries and regions
  props.cC = hydraConfig.params.cC;
  props.bR = hydraConfig.params.bR;

  if (ninjaConfig.platform === 'm') {
    props.cH = 'm';
  } else if (ninjaConfig.platform === 'd') {
    props.cH = 'd';
  } else {
    props.cH = 'w';
  }

  // Matrix Version
  if (undefined !== regionConfig.version) {
    props.mv = regionConfig.version;
  }

  // Current page
  props.cP = eucex(window.location.href);

  // Environment
  if (ninjaConfig.environment !== 'production') {
    props.env = 'dev';
  }

  // Timestamp
  props.t = new Date().getTime();

  trackWithBeacon(url, props);
}

/**
 * Internal function - Track debug information into hydra error stream
 * @param {string} info - the text to track
 * @param {boolean} [force=false] - force the message to be logged, ignoring the configuration
 */
export function trackDebugInfo(info, force) {
  if (force || checkIfDebugEligible(currentSession)) {
    return trackError('DEBUG_INFO', undefined, undefined, info);
  }

  return undefined;
}

/**
 * Track web-vital metric to Hydra
 * The existance of performance metrics already guarantees modern browsers.
 * We can use modern functions here
 *
 * @param {PerformanceMetric} metric
 * @param {string} pageName
 * @param {(params: Record<string, any>) =>  Record<string, any>} applyPlatformFn
 * @returns
 */
export function trackWebVital(metric, pageName, applyPlatformFn) {
  const url = getProtocol() + HYDRA_HOST + hydraConfig.vitals_path;
  let props = {};

  if (!window.JSON || !(navigator.sendBeacon || window.fetch)) {
    return;
  }

  // Properties

  // No mapping is needed. This events/props will later be moved to be reserved words for ninja
  props.eN = 'web_vital';
  props.web_vital_name = metric.name;
  props.web_vital_value = metric.value;
  props.web_vital_rating = metric.rating;
  props.web_vital_page = pageName;

  props.sl = currentSessionLong;
  if (currentSession) {
    props.s = currentSession;
  } else {
    props.s = '00000000000' + 'x' + randomFactor;
  }

  // Config values, countries and regions
  props.cC = hydraConfig.params.cC;
  props.bR = hydraConfig.params.bR;

  // Matrix Version
  if (regionConfig.version) {
    props.mv = regionConfig.version;
  }

  // Current page
  props.cP = getCurrentPath();

  // Environment
  if (ninjaConfig.environment !== 'production') {
    props.env = 'dev';
  }

  // Timestamp
  props.t = new Date().getTime();

  // Host
  const host = getHost();
  if (host) {
    props.host = host;
  }

  props = applyPlatformFn(props);

  trackWithBeacon(url, props);
}

// export functions
export let trackEvent = ninja.trackEvent;
export let trackPage = ninja.trackPage;
export let checkParam = ninja.checkParam;
