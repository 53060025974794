import { configTracking } from '../config/configTracking';
import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { Trackers } from '../const';
import { collectCalls } from '../core';
import { makeMapping, makeObjectMapping } from '../mapping';
import { ajaxCall, deucex, eucex, getProtocol, objectToQueryString } from '../utils';

let isLinkEvent = false;
let SCRIPT_URL = 'www.facebook.com/tr';

/**
 * Track event
 * @param {Record<string, any>} params
 * @returns {void}
 */
export function trackEvent(params) {
  let eventData = [];
  let eventName;
  let newValues;

  if (params.eventData.category) {
    eventData.push(params.eventData.category);
  }
  if (params.eventData.action) {
    eventData.push(params.eventData.action);
  }
  if (params.eventData.label) {
    eventData.push(params.eventData.label);
  }
  if (params.eventData.value) {
    eventData.push(params.eventData.value);
  }

  eventName = eventData.join('_');
  newValues = makeMapping(Trackers.F, 'trackEvent', eventName);
  if (newValues.key === false || newValues.value === false) {
    onHitSentOk();
    return;
  }

  eventName = newValues.value;

  let url = getUrl(eventName, params);

  if (url.length === 0) {
    onHitSentOk();
  } else if (!configTracking.unitTest) {
    // Do not track errors - it can have false positives.
    ajaxCall('get', url, onHitSentOk);
  }
}

/**
 * Track page
 * @param {Record<string, any>} params
 * @returns {void}
 */
export function trackPage(params) {
  let newValues = makeMapping(Trackers.F, 'trackPage', params.pageName);

  if (newValues.key === false || newValues.value === false) {
    return;
  }
  let pageName = deucex(newValues.value);

  let url = getUrl(pageName, params);

  if (url.length === 0) {
    onHitSentOk();
  } else if (!configTracking.unitTest) {
    // Do not track errors - it can have false positives.
    ajaxCall('get', url, onHitSentOk);
  }
}

/**
 * Track link event
 * @param {Record<string, any>} params
 * @returns {void}
 */
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

/**
 * Get formatted URL params
 * Creates a mapping of custom params, and encoding them for use in a GET request
 * @param {Record<string, any>} params
 * @returns {String}
 */
function getUrlParams(params) {
  const urlParams = {};
  const customParams = makeObjectMapping(Trackers.F, params.customParams); // mapping is inside

  // Encode custom params
  Object.keys(customParams).forEach(key => {
    urlParams[eucex(key)] = eucex(customParams[key]);
  });

  let structuredString = '';

  Object.keys(urlParams).forEach(key => {
    structuredString += '&cd[' + key + ']=' + urlParams[key];
  });

  return structuredString;
}

/**
 * Generates the URL for tracking by combining the base URL, tracking parameters and additional parameters passed in.
 * @param {string} name - The name of the event or page being tracked.
 * @param {Record<string, any>} params
 * @returns {string}
 */
function getUrl(name, params) {
  let url = getProtocol() + SCRIPT_URL;
  let urlParams = {
    id: getToken(),
    ev: name,
  };

  url += '?' + objectToQueryString(urlParams) + getUrlParams(params);

  return url;
}

// Internal function - Callback after finish one hit
function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}

/**
 * Get token for facebook tracker from region config
 * @returns {String}
 */
function getToken() {
  let token = regionConfig.custom[ninjaConfig.siteUrl].config[Trackers.F].code;
  if (ninjaConfig.environment !== 'production') {
    // Allow Overwrite only on non-prod
    if (ninjaConfig.custom && ninjaConfig.custom.facebook && ninjaConfig.custom.facebook.siteCode) {
      token = ninjaConfig.custom.facebook.siteCode;
    }
  }
  return token;
}
