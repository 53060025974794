import hydraConfig from '../config/hydra';
import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { getItmInvite } from './utils';

/**
 * Get list of default properties shared between trackers
 * @param {Record<string, any>} params Tracking params
 * @returns {Record<string, any>}
 */
export function getDefaultParams(params) {
  const defaultParams = {};

  defaultParams.cC = hydraConfig.params.cC;
  defaultParams.bR = hydraConfig.params.bR;

  // Matrix Version
  if (regionConfig.version !== undefined) {
    defaultParams.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    defaultParams.env = 'dev';
  }

  // eventType
  if (params.eventData && params.eventData.category) {
    defaultParams.tN = 'e';
  } else if (params.pageName) {
    defaultParams.tN = 'p';
  }

  return defaultParams;
}

// Get custom params
export function getCustomParams(eventData, disablePropertyPropagation = false) {
  // if propagation is disabled, use only the current event data to construct custom params. Else use all previous events
  let eventsToCheck = [];

  if (disablePropertyPropagation) {
    if (eventData) {
      eventsToCheck = [eventData];
    }
  } else {
    eventsToCheck = ninjaConfig.dataLayer;
  }

  const customParams = {};
  const itm = getItmInvite();

  if (itm) {
    // itm needs to be custom param because it needs to be mapped
    customParams.itm = itm;
  }

  for (const event of eventsToCheck) {
    // do not include non-processed events in the prop inheritance chain
    if (event.processed !== true) {
      continue;
    }

    for (const [subKey, value] of Object.entries(event)) {
      if (
        !ninjaConfig.specialNames.includes(subKey) &&
        subKey.slice(0, 4) !== 'gtm.' &&
        !['bind', 'push', 'prototype', 'eventTimeout'].includes(subKey)
      ) {
        customParams[subKey] = value;
      }
    }
  }

  return customParams;
}
